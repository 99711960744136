<template>
  <div class="vx-col w-full">
    <div class="vx-row">
      <div class="vx-col w-full">
        <vx-card title="เลือกวันเวลา">
          <label>เริ่มต้น: </label>
          <flat-pickr :config="configdateTimePicker" v-model="before_datetime" placeholder="Date Time" />
          <br>
          <br>
          <label> สิ้นสุด: </label>
          <flat-pickr :config="configdateTimePicker" v-model="after_datetime" placeholder="Date Time" />
          <br>
          <br>
          <vs-button color="primary" @click="selectdate_time ()">ค้นหา</vs-button>
        </vx-card>

      </div>


    </div>
    <br>
    <div class="vx-row">

      <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4" @click="$store.state.AppActiveUser.permissions.report.action ? activePrompt1=true : null">
        <statistics-card-line hideChart class="mb-base" icon="UsersIcon" icon-right :statistic="countuser.topup"
          statisticTitle="เติมเงิน / คน" color="success" />
      </div>
      <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4" @click="$store.state.AppActiveUser.permissions.report.action ? activePrompt2=true : null">
        <statistics-card-line hideChart class="mb-base" icon="UserCheckIcon" icon-right
          :statistic="countuser.sagame" statisticTitle="SAGAME / คน" color="warning" />
      </div>
       <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4" @click="$store.state.AppActiveUser.permissions.report.action ? activePrompt3=true : null">
        <statistics-card-line hideChart class="mb-base" icon="UserCheckIcon" icon-right
          :statistic="countuser.sexygame" statisticTitle="SEXYGAME / คน" color="warning" />
      </div>
       <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4" @click="$store.state.AppActiveUser.permissions.report.action ? activePrompt4=true : null">
        <statistics-card-line hideChart class="mb-base" icon="UserCheckIcon" icon-right
          :statistic="countuser.jokergame" statisticTitle="JOKERSLOT / คน" color="warning" />
      </div>
      <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4" @click="$store.state.AppActiveUser.permissions.report.action ? activePrompt5=true : null">
        <statistics-card-line hideChart class="mb-base" icon="UserCheckIcon" icon-right
          :statistic="countuser.pggame" statisticTitle="PGSLOT / คน" color="warning" />
      </div>
            <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4" @click="$store.state.AppActiveUser.permissions.report.action ? activePrompt6=true : null">
        <statistics-card-line hideChart class="mb-base" icon="UserCheckIcon" icon-right
          :statistic="countuser.kmgame" statisticTitle="KM GAME / คน" color="warning" />
      </div>
        <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4" @click="$store.state.AppActiveUser.permissions.report.action ? activePrompt7=true : null">
        <statistics-card-line hideChart class="mb-base" icon="UserCheckIcon" icon-right
          :statistic="countuser.wmgame" statisticTitle="WM GAME / คน" color="warning" />
      </div>
      <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4" @click="$store.state.AppActiveUser.permissions.report.action ? activePrompt8=true : null">
        <statistics-card-line hideChart class="mb-base" icon="UserCheckIcon" icon-right
          :statistic="countuser.ambgame" statisticTitle="AMB GAME / คน" color="warning" />
      </div>
            <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4" @click="$store.state.AppActiveUser.permissions.report.action ? activePrompt9=true : null">
        <statistics-card-line hideChart class="mb-base" icon="UserCheckIcon" icon-right
          :statistic="countuser.sbobet" statisticTitle="SBOBET / คน" color="warning" />
      </div>
            <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4" @click="$store.state.AppActiveUser.permissions.report.action ? activePrompt10=true : null">
        <statistics-card-line hideChart class="mb-base" icon="UserCheckIcon" icon-right
          :statistic="countuser.gssslot" statisticTitle="GSS SLOT / คน" color="warning" />
      </div>
            <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4" @click="$store.state.AppActiveUser.permissions.report.action ? activePrompt11=true : null">
        <statistics-card-line hideChart class="mb-base" icon="UserCheckIcon" icon-right
          :statistic="countuser.sppslot" statisticTitle="SimplePlay SLOT / คน" color="warning" />
      </div>
       <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4" @click="$store.state.AppActiveUser.permissions.report.action ? activePrompt12=true : null">
        <statistics-card-line hideChart class="mb-base" icon="UserCheckIcon" icon-right
          :statistic="countuser.dggame" statisticTitle="DreamGaming / คน" color="warning" />
      </div>
       <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4" @click="$store.state.AppActiveUser.permissions.report.action ? activePrompt13=true : null">
        <statistics-card-line hideChart class="mb-base" icon="UserCheckIcon" icon-right
          :statistic="countuser.mggame" statisticTitle="Micro Gameing / คน" color="warning" />
      </div>
       <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4" @click="$store.state.AppActiveUser.permissions.report.action ? activePrompt14=true : null">
        <statistics-card-line hideChart class="mb-base" icon="UserCheckIcon" icon-right
          :statistic="countuser.evogame" statisticTitle="Evo play / คน" color="warning" />
      </div>
      <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4" @click="$store.state.AppActiveUser.permissions.report.action ? activePrompt15=true : null">
        <statistics-card-line hideChart class="mb-base" icon="UserCheckIcon" icon-right
          :statistic="countuser.ppgame" statisticTitle="PP GAME / คน" color="warning" />
      </div>
            <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4" @click="$store.state.AppActiveUser.permissions.report.action ? activePrompt16=true : null">
        <statistics-card-line hideChart class="mb-base" icon="UserCheckIcon" icon-right
          :statistic="countuser.rchgame" statisticTitle="RICH88 GAME / คน" color="warning" />
      </div>
            <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4" @click="$store.state.AppActiveUser.permissions.report.action ? activePrompt17=true : null">
        <statistics-card-line hideChart class="mb-base" icon="UserCheckIcon" icon-right
          :statistic="countuser.dragongame" statisticTitle="DRAGON GAME / คน" color="warning" />
      </div>
            <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4" @click="$store.state.AppActiveUser.permissions.report.action ? activePrompt18=true : null">
        <statistics-card-line hideChart class="mb-base" icon="UserCheckIcon" icon-right
          :statistic="countuser.allbetgame" statisticTitle="ALLBET GAME / คน" color="warning" />
      </div>
                  <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4" @click="$store.state.AppActiveUser.permissions.report.action ? activePrompt19=true : null">
        <statistics-card-line hideChart class="mb-base" icon="UserCheckIcon" icon-right
          :statistic="countuser.hggame" statisticTitle="HOTGRAPH GAME / คน" color="warning" />
      </div>
      <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4" @click="$store.state.AppActiveUser.permissions.report.action ? activePrompt20=true : null">
        <statistics-card-line hideChart class="mb-base" icon="UserCheckIcon" icon-right
          :statistic="countuser.aggame" statisticTitle="ASIA GAMING / คน" color="warning" />
      </div>
      <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4" @click="$store.state.AppActiveUser.permissions.report.action ? activePrompt21=true : null">
        <statistics-card-line hideChart class="mb-base" icon="UserCheckIcon" icon-right
          :statistic="countuser.xingame" statisticTitle="XIN SLOT / คน" color="warning" />
      </div>
      <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4" @click="$store.state.AppActiveUser.permissions.report.action ? activePrompt22=true : null">
        <statistics-card-line hideChart class="mb-base" icon="UserCheckIcon" icon-right
          :statistic="countuser.hdggame" statisticTitle="HUAY DRAGON / คน" color="warning" />
      </div>
      <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4" @click="$store.state.AppActiveUser.permissions.report.action ? activePrompt23=true : null">
        <statistics-card-line hideChart class="mb-base" icon="UserCheckIcon" icon-right
          :statistic="countuser.tfggame" statisticTitle="TF GAMING / คน" color="warning" />
      </div>
      <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4" >
        <statistics-card-line hideChart class="mb-base" icon="UserCheckIcon" icon-right
          :statistic="countuser.spggame" statisticTitle="SPADE GAMING / คน" color="warning" />
      </div>
      <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4" >
        <statistics-card-line hideChart class="mb-base" icon="UserCheckIcon" icon-right
          :statistic="countuser.dgsgame" statisticTitle="DRAGOONSOFT GAMING / คน" color="warning" />
      </div>
        <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4" >
        <statistics-card-line hideChart class="mb-base" icon="UserCheckIcon" icon-right
          :statistic="countuser.uggame" statisticTitle="UNITED GAMING / คน" color="warning" />
      </div>
      <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4" >
        <statistics-card-line hideChart class="mb-base" icon="UserCheckIcon" icon-right
          :statistic="countuser.macgame" statisticTitle="MARIOCLUB GAMING / คน" color="warning" />
      </div>
      <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4" >
        <statistics-card-line hideChart class="mb-base" icon="UserCheckIcon" icon-right
          :statistic="countuser.ibcgame" statisticTitle="IBC GAMING / คน" color="warning" />
      </div>
      <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4" >
        <statistics-card-line hideChart class="mb-base" icon="UserCheckIcon" icon-right
          :statistic="countuser.jlgame" statisticTitle="JILI GAMING / คน" color="warning" />
      </div>
      <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4" >
        <statistics-card-line hideChart class="mb-base" icon="UserCheckIcon" icon-right
          :statistic="countuser.gcpgame" statisticTitle="GIOCOPLUS GAMING / คน" color="warning" />
      </div>
      <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4" >
        <statistics-card-line hideChart class="mb-base" icon="UserCheckIcon" icon-right
          :statistic="countuser.qtgame" statisticTitle="QTECH GAMING / คน" color="warning" />
      </div>
      <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4" >
        <statistics-card-line hideChart class="mb-base" icon="UserCheckIcon" icon-right
          :statistic="countuser.obgame" statisticTitle="OB GAMING / คน" color="warning" />
      </div>
      <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4" >
        <statistics-card-line hideChart class="mb-base" icon="UserCheckIcon" icon-right
          :statistic="countuser.gcgame" statisticTitle="G-CLUB GAMING / คน" color="warning" />
      </div>
      <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4" >
        <statistics-card-line hideChart class="mb-base" icon="UserCheckIcon" icon-right
          :statistic="countuser.llkgame" statisticTitle="LALIKA SPORT / คน" color="warning" />
      </div>
      <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4" >
        <statistics-card-line hideChart class="mb-base" icon="UserCheckIcon" icon-right
          :statistic="countuser.jergame" statisticTitle="JERRY GAMING / คน" color="warning" />
      </div>
    </div>
    <vs-prompt title="Active เติมเงิน" class="export-options" @cancle="clearFields"
      @accept="exportToExcel(activeuser.excel.topup,'Active เติมเงิน',headerVal)" accept-text="Export" @close="clearFields"
      :active.sync="activePrompt1">
      <vs-input v-model="fileName" placeholder="Enter File Name.." class="w-full" />
      <v-select v-model="selectedFormat" :options="formats" class="my-4" />
      <div class="flex">
        <span class="mr-4">Cell Auto Width:</span>
        <vs-switch v-model="cellAutoWidth">Cell Auto Width</vs-switch>
      </div>
    </vs-prompt>
    <!-- <vs-prompt title="Active Sagame" class="export-options" @cancle="clearFields"
      @accept="exportToExcel(activeuser.excel.sagame, 'Active Sagame',headerVal1)" accept-text="Export" @close="clearFields"
      :active.sync="activePrompt2">
      <vs-input v-model="fileName" placeholder="Enter File Name.." class="w-full" />
      <v-select v-model="selectedFormat" :options="formats" class="my-4" />
      <div class="flex">
        <span class="mr-4">Cell Auto Width:</span>
        <vs-switch v-model="cellAutoWidth">Cell Auto Width</vs-switch>
      </div>
    </vs-prompt>
    <vs-prompt title="Active Sexygame" class="export-options" @cancle="clearFields"
      @accept="exportToExcel(activeuser.excel.sexygame, 'Active Sexygame',headerVal2)" accept-text="Export" @close="clearFields"
      :active.sync="activePrompt3">
      <vs-input v-model="fileName" placeholder="Enter File Name.." class="w-full" />
      <v-select v-model="selectedFormat" :options="formats" class="my-4" />
      <div class="flex">
        <span class="mr-4">Cell Auto Width:</span>
        <vs-switch v-model="cellAutoWidth">Cell Auto Width</vs-switch>
      </div>
    </vs-prompt>
    <vs-prompt title="Active JokerSlot" class="export-options" @cancle="clearFields"
      @accept="exportToExcel(activeuser.excel.jokergame, 'Active JokerGame',headerVal3)" accept-text="Export" @close="clearFields"
      :active.sync="activePrompt4">
      <vs-input v-model="fileName" placeholder="Enter File Name.." class="w-full" />
      <v-select v-model="selectedFormat" :options="formats" class="my-4" />
      <div class="flex">
        <span class="mr-4">Cell Auto Width:</span>
        <vs-switch v-model="cellAutoWidth">Cell Auto Width</vs-switch>
      </div>
    </vs-prompt>
    <vs-prompt title="Active PgSlot" class="export-options" @cancle="clearFields"
      @accept="exportToExcel(activeuser.excel.pggame, 'Active PGslot',headerVal4)" accept-text="Export" @close="clearFields"
      :active.sync="activePrompt5">
      <vs-input v-model="fileName" placeholder="Enter File Name.." class="w-full" />
      <v-select v-model="selectedFormat" :options="formats" class="my-4" />
      <div class="flex">
        <span class="mr-4">Cell Auto Width:</span>
        <vs-switch v-model="cellAutoWidth">Cell Auto Width</vs-switch>
      </div>
    </vs-prompt>
        <vs-prompt title="Active KMgame" class="export-options" @cancle="clearFields"
      @accept="exportToExcel(activeuser.excel.kmgame, 'Active KMgame',headerVal5)" accept-text="Export" @close="clearFields"
      :active.sync="activePrompt6">
      <vs-input v-model="fileName" placeholder="Enter File Name.." class="w-full" />
      <v-select v-model="selectedFormat" :options="formats" class="my-4" />
      <div class="flex">
        <span class="mr-4">Cell Auto Width:</span>
        <vs-switch v-model="cellAutoWidth">Cell Auto Width</vs-switch>
      </div>
    </vs-prompt>
            <vs-prompt title="Active WMgame" class="export-options" @cancle="clearFields"
      @accept="exportToExcel(activeuser.excel.wmgame, 'Active WMgame',headerVal6)" accept-text="Export" @close="clearFields"
      :active.sync="activePrompt7">
      <vs-input v-model="fileName" placeholder="Enter File Name.." class="w-full" />
      <v-select v-model="selectedFormat" :options="formats" class="my-4" />
      <div class="flex">
        <span class="mr-4">Cell Auto Width:</span>
        <vs-switch v-model="cellAutoWidth">Cell Auto Width</vs-switch>
      </div>
    </vs-prompt>
     <vs-prompt title="Active AMBpoker" class="export-options" @cancle="clearFields"
      @accept="exportToExcel(activeuser.excel.ambgame, 'Active AMBgame',headerVal7)" accept-text="Export" @close="clearFields"
      :active.sync="activePrompt8">
      <vs-input v-model="fileName" placeholder="Enter File Name.." class="w-full" />
      <v-select v-model="selectedFormat" :options="formats" class="my-4" />
      <div class="flex">
        <span class="mr-4">Cell Auto Width:</span>
        <vs-switch v-model="cellAutoWidth">Cell Auto Width</vs-switch>
      </div>
    </vs-prompt>
         <vs-prompt title="Active SBOBET" class="export-options" @cancle="clearFields"
      @accept="exportToExcel(activeuser.excel.sbobet, 'Active SBOBET',headerVal8)" accept-text="Export" @close="clearFields"
      :active.sync="activePrompt9">
      <vs-input v-model="fileName" placeholder="Enter File Name.." class="w-full" />
      <v-select v-model="selectedFormat" :options="formats" class="my-4" />
      <div class="flex">
        <span class="mr-4">Cell Auto Width:</span>
        <vs-switch v-model="cellAutoWidth">Cell Auto Width</vs-switch>
      </div>
    </vs-prompt>
         <vs-prompt title="Active GSS Slot" class="export-options" @cancle="clearFields"
      @accept="exportToExcel(activeuser.excel.gssslot,'Active GSS SLOT',headerVal9)" accept-text="Export" @close="clearFields"
      :active.sync="activePrompt10">
      <vs-input v-model="fileName" placeholder="Enter File Name.." class="w-full" />
      <v-select v-model="selectedFormat" :options="formats" class="my-4" />
      <div class="flex">
        <span class="mr-4">Cell Auto Width:</span>
        <vs-switch v-model="cellAutoWidth">Cell Auto Width</vs-switch>
      </div>
    </vs-prompt>
         <vs-prompt title="Active SPP SLOT" class="export-options" @cancle="clearFields"
      @accept="exportToExcel(activeuser.excel.sppslot, 'Active SPP SLOT',headerVal11)" accept-text="Export" @close="clearFields"
      :active.sync="activePrompt11">
      <vs-input v-model="fileName" placeholder="Enter File Name.." class="w-full" />
      <v-select v-model="selectedFormat" :options="formats" class="my-4" />
      <div class="flex">
        <span class="mr-4">Cell Auto Width:</span>
        <vs-switch v-model="cellAutoWidth">Cell Auto Width</vs-switch>
      </div>
    </vs-prompt>
       <vs-prompt title="Active DreamGaming" class="export-options" @cancle="clearFields"
      @accept="exportToExcel(activeuser.excel.dg, 'Active DreamGaming',headerVal12)" accept-text="Export" @close="clearFields"
      :active.sync="activePrompt12">
      <vs-input v-model="fileName" placeholder="Enter File Name.." class="w-full" />
      <v-select v-model="selectedFormat" :options="formats" class="my-4" />
      <div class="flex">
        <span class="mr-4">Cell Auto Width:</span>
        <vs-switch v-model="cellAutoWidth">Cell Auto Width</vs-switch>
      </div>
    </vs-prompt>
       <vs-prompt title="Active Micro Gameing" class="export-options" @cancle="clearFields"
      @accept="exportToExcel(activeuser.excel.mg, 'Active Micro Gameing',headerVal13)" accept-text="Export" @close="clearFields"
      :active.sync="activePrompt13">
      <vs-input v-model="fileName" placeholder="Enter File Name.." class="w-full" />
      <v-select v-model="selectedFormat" :options="formats" class="my-4" />
      <div class="flex">
        <span class="mr-4">Cell Auto Width:</span>
        <vs-switch v-model="cellAutoWidth">Cell Auto Width</vs-switch>
      </div>
    </vs-prompt>
       <vs-prompt title="Active Evo play" class="export-options" @cancle="clearFields"
      @accept="exportToExcel(activeuser.excel.evo, 'Active Evo play',headerVal14)" accept-text="Export" @close="clearFields"
      :active.sync="activePrompt14">
      <vs-input v-model="fileName" placeholder="Enter File Name.." class="w-full" />
      <v-select v-model="selectedFormat" :options="formats" class="my-4" />
      <div class="flex">
        <span class="mr-4">Cell Auto Width:</span>
        <vs-switch v-model="cellAutoWidth">Cell Auto Width</vs-switch>
      </div>
    </vs-prompt>
       <vs-prompt title="Active PP GAME" class="export-options" @cancle="clearFields"
      @accept="exportToExcel(activeuser.excel.pp, 'Active PP GAME',headerVal15)" accept-text="Export" @close="clearFields"
      :active.sync="activePrompt15">
      <vs-input v-model="fileName" placeholder="Enter File Name.." class="w-full" />
      <v-select v-model="selectedFormat" :options="formats" class="my-4" />
      <div class="flex">
        <span class="mr-4">Cell Auto Width:</span>
        <vs-switch v-model="cellAutoWidth">Cell Auto Width</vs-switch>
      </div>
    </vs-prompt>
     <vs-prompt title="Active RICH88 GAME" class="export-options" @cancle="clearFields"
      @accept="exportToExcel(activeuser.excel.rch, 'Active RICH88 GAME',headerVal16)" accept-text="Export" @close="clearFields"
      :active.sync="activePrompt16">
      <vs-input v-model="fileName" placeholder="Enter File Name.." class="w-full" />
      <v-select v-model="selectedFormat" :options="formats" class="my-4" />
      <div class="flex">
        <span class="mr-4">Cell Auto Width:</span>
        <vs-switch v-model="cellAutoWidth">Cell Auto Width</vs-switch>
      </div>
    </vs-prompt>
           <vs-prompt title="Active DRAGON GAME" class="export-options" @cancle="clearFields"
      @accept="exportToExcel(activeuser.excel.dragon, 'Active DRAGON GAME',headerVal17)" accept-text="Export" @close="clearFields"
      :active.sync="activePrompt17">
      <vs-input v-model="fileName" placeholder="Enter File Name.." class="w-full" />
      <v-select v-model="selectedFormat" :options="formats" class="my-4" />
      <div class="flex">
        <span class="mr-4">Cell Auto Width:</span>
        <vs-switch v-model="cellAutoWidth">Cell Auto Width</vs-switch>
      </div>
    </vs-prompt>
    <vs-prompt title="Active ALLBET GAME" class="export-options" @cancle="clearFields"
      @accept="exportToExcel(activeuser.excel.allbet, 'Active ALLBET GAME',headerVal18)" accept-text="Export" @close="clearFields"
      :active.sync="activePrompt18">
      <vs-input v-model="fileName" placeholder="Enter File Name.." class="w-full" />
      <v-select v-model="selectedFormat" :options="formats" class="my-4" />
      <div class="flex">
        <span class="mr-4">Cell Auto Width:</span>
        <vs-switch v-model="cellAutoWidth">Cell Auto Width</vs-switch>
      </div>
    </vs-prompt>

        <vs-prompt title="Active HOTGRAPH GAME" class="export-options" @cancle="clearFields"
      @accept="exportToExcel(activeuser.excel.hg, 'Active HOTGRAPH GAME',headerVal19)" accept-text="Export" @close="clearFields"
      :active.sync="activePrompt19">
      <vs-input v-model="fileName" placeholder="Enter File Name.." class="w-full" />
      <v-select v-model="selectedFormat" :options="formats" class="my-4" />
      <div class="flex">
        <span class="mr-4">Cell Auto Width:</span>
        <vs-switch v-model="cellAutoWidth">Cell Auto Width</vs-switch>
      </div>
    </vs-prompt>

      <vs-prompt title="Active ASIA GAMING" class="export-options" @cancle="clearFields"
      @accept="exportToExcel(activeuser.excel.ag, 'Active ASIA GAMING',headerVal20)" accept-text="Export" @close="clearFields"
      :active.sync="activePrompt20">
      <vs-input v-model="fileName" placeholder="Enter File Name.." class="w-full" />
      <v-select v-model="selectedFormat" :options="formats" class="my-4" />
      <div class="flex">
        <span class="mr-4">Cell Auto Width:</span>
        <vs-switch v-model="cellAutoWidth">Cell Auto Width</vs-switch>
      </div>
    </vs-prompt>

      <vs-prompt title="Active XIN SLOT" class="export-options" @cancle="clearFields"
      @accept="exportToExcel(activeuser.excel.xin, 'Active XIN SLOT',headerVal21)" accept-text="Export" @close="clearFields"
      :active.sync="activePrompt21">
      <vs-input v-model="fileName" placeholder="Enter File Name.." class="w-full" />
      <v-select v-model="selectedFormat" :options="formats" class="my-4" />
      <div class="flex">
        <span class="mr-4">Cell Auto Width:</span>
        <vs-switch v-model="cellAutoWidth">Cell Auto Width</vs-switch>
      </div>
    </vs-prompt>

     <vs-prompt title="Active HUAY DRAGON" class="export-options" @cancle="clearFields"
      @accept="exportToExcel(activeuser.excel.hdg, 'Active HUAY DRAGON',headerVal22)" accept-text="Export" @close="clearFields"
      :active.sync="activePrompt22">
      <vs-input v-model="fileName" placeholder="Enter File Name.." class="w-full" />
      <v-select v-model="selectedFormat" :options="formats" class="my-4" />
      <div class="flex">
        <span class="mr-4">Cell Auto Width:</span>
        <vs-switch v-model="cellAutoWidth">Cell Auto Width</vs-switch>
      </div>
    </vs-prompt>
    <vs-prompt title="Active TF GAMING" class="export-options" @cancle="clearFields"
      @accept="exportToExcel(activeuser.excel.tfg, 'Active TF GAMING',headerVal23)" accept-text="Export" @close="clearFields"
      :active.sync="activePrompt23">
      <vs-input v-model="fileName" placeholder="Enter File Name.." class="w-full" />
      <v-select v-model="selectedFormat" :options="formats" class="my-4" />
      <div class="flex">
        <span class="mr-4">Cell Auto Width:</span>
        <vs-switch v-model="cellAutoWidth">Cell Auto Width</vs-switch>
      </div>
    </vs-prompt> -->
  </div>

</template>

<script>
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import StatisticsCardLine from '@/components/statistics-cards/StatisticsCardLine.vue'
import axios from '../../axios'
import vSelect from 'vue-select'
import moment from 'moment'

export default {
  data () {
    return {
      before_datetime: moment.tz('Asia/Bangkok').format('YYYY-MM-DD 00:00'),
      after_datetime: moment.tz('Asia/Bangkok').add(1, 'days').format('YYYY-MM-DD 00:00'),
      configdateTimePicker: {
        enableTime: true,
        dateFormat: 'Y-m-d H:i',
        time_24hr: true
      },
      fileName: '',
      headerTitle: ['Username', 'เบอร์โทรศัพท์', 'LINE'],
      headerVal: ['topup_username', 'member_phone', 'member_line'],
      headerVal1: ['username', 'member_phone', 'member_line'],
      headerVal2: ['username', 'member_phone', 'member_line'],
      headerVal3: ['username', 'member_phone', 'member_line'],
      headerVal4: ['username', 'member_phone', 'member_line'],
      headerVal5: ['username', 'member_phone', 'member_line'],
      headerVal6: ['username', 'member_phone', 'member_line'],
      headerVal7: ['username', 'member_phone', 'member_line'],
      headerVal8: ['username', 'member_phone', 'member_line'],
      headerVal9: ['username', 'member_phone', 'member_line'],
      headerVal10: ['username', 'member_phone', 'member_line'],
      headerVal11: ['username', 'member_phone', 'member_line'],
      headerVal12: ['username', 'member_phone', 'member_line'],
      headerVal13: ['username', 'member_phone', 'member_line'],
      headerVal14: ['username', 'member_phone', 'member_line'],
      headerVal15: ['username', 'member_phone', 'member_line'],
      headerVal16: ['username', 'member_phone', 'member_line'],
      headerVal17: ['username', 'member_phone', 'member_line'],
      headerVal18: ['username', 'member_phone', 'member_line'],
      headerVal19: ['username', 'member_phone', 'member_line'],
      headerVal20: ['username', 'member_phone', 'member_line'],
      headerVal21: ['username', 'member_phone', 'member_line'],
      headerVal22: ['username', 'member_phone', 'member_line'],
      headerVal23: ['username', 'member_phone', 'member_line'],
      activeuser :
      {
        count : {},
        excel : {}
      },
      countuser: {
        topup:''
        // sagame:'',
        // sexygame:'',
        // jokergame:'',
        // pggame:'',
        // kmgame:'',
        // wmgame:'',
        // ambgame:'',
        // sbobet:'',
        // gssslot:'',
        // sppslot:'',
        // dggame : '',
        // mggame : '',
        // evogame : '',
        // ppgame : '',
        // rchgame: '',
        // dragongame:'',
        // allbetgame:'',
        // hggame:'',
        // aggame:'',
        // xingame:'',
        // hdggame:'',
        // tfggame:''
      },
      data: [],
      activePrompt1: false,
      activePrompt2: false,
      activePrompt3: false,
      activePrompt4: false,
      activePrompt5: false,
      activePrompt6: false,
      activePrompt7: false,
      activePrompt8: false,
      activePrompt9: false,
      activePrompt10: false,
      activePrompt11: false,
      activePrompt12: false,
      activePrompt13: false,
      activePrompt14: false,
      activePrompt15: false,
      activePrompt16: false,
      activePrompt17: false,
      activePrompt18: false,
      activePrompt19: false,
      activePrompt20: false,
      activePrompt21: false,
      activePrompt22: false,
      activePrompt23: false,
      formats: ['xlsx', 'csv', 'txt'],
      selectedFormat: 'xlsx',
      cellAutoWidth: true


    }
  },
  components: {
    flatPickr,
    StatisticsCardLine,
    vSelect
  },
  async mounted () {
    // await axios
    //   .get(`reportactiveuser/activeuser/${this.before_datetime }/${ this.after_datetime}`)
    //   .then(response => (this.activeuser.count = response.data))
    // this.countuser.topup = this.activeuser.count.topupuser
    // this.countuser.sagame = this.activeuser.count.playersa
    // this.countuser.sexygame =  this.activeuser.count.playersexy
    // this.countuser.jokergame = this.activeuser.count.playerjoker
    // this.countuser.pggame = this.activeuser.count.playerpggame
    // this.countuser.kmgame = this.activeuser.count.playerkmgame
    // this.countuser.wmgame = this.activeuser.count.playerwm
    // this.countuser.ambgame = this.activeuser.count.playeramb
    // this.countuser.sbobet = this.activeuser.count.playersbo
    // this.countuser.gssslot = this.activeuser.count.playergss
    // this.countuser.sppslot = this.activeuser.count.playerspp
    // this.countuser.dggame = this.activeuser.count.playerdg
    // this.countuser.mggame = this.activeuser.count.playermg
    // this.countuser.evogame = this.activeuser.count.playerevo
    // this.countuser.ppgame = this.activeuser.count.playerpp
    // this.countuser.rchgame = this.activeuser.count.playerrch
    // this.countuser.dragongame = this.activeuser.count.playerdragon
    // this.countuser.allbetgame = this.activeuser.count.playerallbet
    // this.countuser.hggame = this.activeuser.count.playerhg
    // this.countuser.aggame = this.activeuser.count.playerag
    // this.countuser.xingame = this.activeuser.count.playerxin
    // this.countuser.hdggame = this.activeuser.count.playerhdg
    // this.countuser.tfggame = this.activeuser.count.playertfg
    // this.countuser.spggame = this.activeuser.count.playerspg
    // this.countuser.dgsgame = this.activeuser.count.playerdgs
    // this.countuser.uggame = this.activeuser.count.playerug
    // this.countuser.macgame = this.activeuser.count.playermac
    // this.countuser.ibcgame = this.activeuser.count.playeribc
    // this.countuser.jlgame = this.activeuser.count.playerjl
    // await axios
    //   .get(`reportactiveuser/dataactiveuser/${this.before_datetime }/${ this.after_datetime}`)
    //   .then(response => (this.activeuser.excel = response.data))

    // this.fileName = `Report_Activeuser ${this.before_datetime} ${this.after_datetime}`
  },
  methods: {
    async selectdate_time () {
      await axios
        .get(`reportactiveuser/activeuser/${this.before_datetime }/${ this.after_datetime}`)
        .then(response => (this.activeuser.count = response.data))
      this.countuser.topup = this.activeuser.count.topupuser
      this.countuser.sagame = this.activeuser.count.playersa
      this.countuser.sexygame =  this.activeuser.count.playersexy
      this.countuser.jokergame = this.activeuser.count.playerjoker
      this.countuser.pggame = this.activeuser.count.playerpggame
      this.countuser.kmgame = this.activeuser.count.playerkmgame
      this.countuser.wmgame = this.activeuser.count.playerwm
      this.countuser.ambgame = this.activeuser.count.playeramb
      this.countuser.sbobet = this.activeuser.count.playersbo
      this.countuser.gssslot = this.activeuser.count.playergss
      this.countuser.sppslot = this.activeuser.count.playerspp
      this.countuser.dggame = this.activeuser.count.playerdg
      this.countuser.mggame = this.activeuser.count.playermg
      this.countuser.evogame = this.activeuser.count.playerevo
      this.countuser.ppgame = this.activeuser.count.playerpp
      this.countuser.rchgame = this.activeuser.count.playerrch
      this.countuser.dragongame = this.activeuser.count.playerdragon
      this.countuser.allbetgame = this.activeuser.count.playerallbet
      this.countuser.hggame = this.activeuser.count.playerhg
      this.countuser.aggame = this.activeuser.count.playerag
      this.countuser.xingame = this.activeuser.count.playerxin
      this.countuser.hdggame = this.activeuser.count.playerhdg
      this.countuser.tfggame = this.activeuser.count.playertfg
      this.countuser.spggame = this.activeuser.count.playerspg
      this.countuser.dgsgame = this.activeuser.count.playerdgs
      this.countuser.uggame = this.activeuser.count.playerug
      this.countuser.macgame = this.activeuser.count.playermac
      this.countuser.ibcgame = this.activeuser.count.playeribc
      this.countuser.jlgame = this.activeuser.count.playerjl
      this.countuser.gcpgame = this.activeuser.count.playergcp
      this.countuser.qtgame = this.activeuser.count.playerqt
      this.countuser.obgame = this.activeuser.count.playerob
      this.countuser.gcgame = this.activeuser.count.playergc
      this.countuser.jergame = this.activeuser.count.playerjer
      this.countuser.llkgame = this.activeuser.count.playerllk
      await axios
        .get(`reportactiveuser/dataactiveuser/${this.before_datetime }/${ this.after_datetime}`)
        .then(response => (this.activeuser.excel = response.data))
      this.fileName = `Report_Activeuser ${this.before_datetime} ${this.after_datetime}`
    },
    exportToExcel (count_data, name, headerVal) {
        import('@/vendor/Export2Excel').then(excel => {
          const list = count_data
          const data = this.formatJson(headerVal, list)
          excel.export_json_to_excel({
            header: this.headerTitle,
            data,
            filename: this.fileName,
            autoWidth: this.cellAutoWidth,
            bookType: this.selectedFormat
          })

          this.clearFields()
        })
    },
    formatJson (filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => {
        // Add col name which needs to be translated
        // if (j === 'timestamp') {
        //   return parseTime(v[j])
        // } else {
        //   return v[j]
        // }

        return v[j]
      }))
    },
    clearFields () {
      this.filename = ''
      this.cellAutoWidth = true
      this.selectedFormat = 'xlsx'
    }

  }
}

</script>

<style>

</style>
